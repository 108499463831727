import { ApiType, CodeViewerProps } from './CodeViewer'

const VOICE_TAG_REGEX = /<voice[^>]*>(.*?)<\/voice>/
export async function getCodeStringFor(apiType: ApiType, language: string, segmentInput: CodeViewerProps) {
  const blockSsml = await segmentInput.clipBodyPromise
  const match = blockSsml.match(VOICE_TAG_REGEX)
  const voiceTagContents = match ? match[1] : ''

  const outputCode = getOutputCode(apiType, language, segmentInput.projectUuid, segmentInput.voiceUuid, voiceTagContents)
  return outputCode.replace(/&#032;/g, ' ')
}
function getOutputCode(apiType: ApiType, language: string, projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  switch (apiType) {
    case 'sync':
      return getSyncCode(language, projectUuid, voiceUuid, voiceTagContents)
    case 'async':
      return getAsyncCode(language, projectUuid, voiceUuid, voiceTagContents)
    case 'streaming':
      return getStreamingCode(language, projectUuid, voiceUuid, voiceTagContents)
    case 'direct sync':
      return getDirectSyncCode(language, projectUuid, voiceUuid, voiceTagContents)
    default:
      throw new Error(`Unknown apiType: ${apiType}`)
  }
}
function getSyncCode(language: string, projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  switch (language) {
    case 'bash':
      return getCurlSyncCode(projectUuid, voiceUuid, voiceTagContents)
    case 'python':
      return getPythonSyncCode(projectUuid, voiceUuid, voiceTagContents)
    case 'javascript':
      return getNodeJsSyncCode(projectUuid, voiceUuid, voiceTagContents)
    default:
      throw new Error(`Unknown language: ${language}`)
  }
}
function getAsyncCode(language: string, projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  switch (language) {
    case 'bash':
      return getCurlAsyncCode(projectUuid, voiceUuid, voiceTagContents)
    case 'python':
      return getPythonAsyncCode(projectUuid, voiceUuid, voiceTagContents)
    case 'javascript':
      return getNodeJsAsyncCode(projectUuid, voiceUuid, voiceTagContents)
    default:
      throw new Error(`Unknown language: ${language}`)
  }
}

function getStreamingCode(language: string, projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  switch (language) {
    case 'javascript':
      return getNodeJsStreamingCode(projectUuid, voiceUuid, voiceTagContents)
    case 'bash':
      return getCurlStreamingCode(projectUuid, voiceUuid, voiceTagContents)
    case 'python':
      return getPythonStreamingCode(projectUuid, voiceUuid, voiceTagContents)
    default:
      throw new Error(`Unknown language: ${language}`)
  }
}

function getDirectSyncCode(language: string, projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  switch (language) {
    case 'bash':
      return getCurlDirectSyncCode(projectUuid, voiceUuid, voiceTagContents)
    default:
      throw new Error(`Unknown language: ${language}`)
  }
}

function getCurlSyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  const escapedContents = voiceTagContents
    .replace(/"/g, '\\"') // Escape double quotes
    .replace(/'/g, "\\'") // Escape single quotes

  return `
curl '${window.location.origin}/api/v2/projects/${projectUuid}/clips' \\
  -H 'Authorization: Token token=YOUR_API_TOKEN' \\
  -H 'Content-Type: application/json' \\
  --data '{
    "body": "${escapedContents}", 
    "voice_uuid":  "${voiceUuid}", 
    "is_public": true, 
    "is_archived": false
  }'`
}

function getPythonSyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  return `
from resemble import Resemble
Resemble.api_key('YOUR_API_TOKEN')
  
project_uuid = '${projectUuid}'
voice_uuid = '${voiceUuid}'
body = """${voiceTagContents}"""

response = Resemble.v2.clips.create_sync(
  project_uuid,
  voice_uuid,
  body,
  title=None,
  sample_rate=None,
  output_format=None,
  precision=None,
  include_timestamps=None,
  is_public=None,
  is_archived=None,
  raw=None
)
clip = response['item']`
}
function getNodeJsSyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  return `
import { Resemble } from '@resemble/node'

Resemble.setApiKey('YOUR_API_TOKEN')
const projectUuid = "${projectUuid}"  
const voiceUuid = "${voiceUuid}"
const response = await Resemble.v2.clips.createSync(projectUuid, {
  body: \`${voiceTagContents}\`,
  voice_uuid: voiceUuid
})`
}
function getNodeJsAsyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  return `
import { Resemble } from '@resemble/node'

Resemble.setApiKey('YOUR_API_TOKEN')
const projectUuid = "${projectUuid}"  
const voiceUuid = "${voiceUuid}"
const response = await Resemble.v2.clips.createAsync(projectUuid, {
  body: \`${voiceTagContents}\`,
  voice_uuid: voiceUuid,
  callback_uri: 'https://example.com'
})`
}
function getPythonAsyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  return `
from resemble import Resemble
Resemble.api_key('YOUR_API_TOKEN')
  
project_uuid = '${projectUuid}'
voice_uuid = '${voiceUuid}'
callback_uri = 'https://example.com/callback/resemble-clip'
body = """${voiceTagContents}"""
  
response = Resemble.v2.clips.create_async(
  project_uuid,
  voice_uuid,
  callback_uri,
  body,
  title=None,
  sample_rate=None,
  output_format=None,
  precision=None,
  include_timestamps=None,
  is_public=None,
  is_archived=None
)
clip = response['item']`
}

function getCurlAsyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  const escapedContents = voiceTagContents
    .replace(/"/g, '\\"') // Escape double quotes
    .replace(/'/g, "\\'") // Escape single quotes

  return `
curl '${window.location.origin}/api/v2/projects/${projectUuid}/clips' \\
    -H 'Authorization: Token token=YOUR_API_TOKEN' \\
    -H 'Content-Type: application/json' \\
    --data '{
      "callback_uri": "<your_callback_uri>", 
      "body": "${escapedContents}", 
      "voice_uuid":  "${voiceUuid}", 
      "is_public": true, 
      "is_archived": false
    }'`
}

function getCurlDirectSyncCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  const escapedContents = JSON.stringify(voiceTagContents)

  return `
curl -X POST p.cluster.resemble.ai/synthesize \\ 
    -H "Content-Type: application/json" \\ 
    -d '{
      "voice_uuid": "${voiceUuid}", 
      "data": ${escapedContents}
    }'`
}

function getCurlStreamingCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  const escapedContents = JSON.stringify(voiceTagContents)

  return `
curl -X POST p.cluster.resemble.ai/stream \\ 
    -H "Content-Type: application/json" \\ 
    -d '{
      "voice_uuid": "${voiceUuid}", 
      "data": ${escapedContents}
    }'`
}

function getPythonStreamingCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  return `
from resemble import Resemble
Resemble.api_key('YOUR_API_TOKEN')
  
# It's very important that you configure the resemble library with the syn_server_url. Streaming will not
# work otherwise. You can obtain this URL by contacting resemble.
Resemble.syn_server_url('<syn_server_url>')
  
project_uuid = '${projectUuid}'
voice_uuid = '${voiceUuid}'
body = """${voiceTagContents}"""
  
try:
  for chunk in Resemble.v2.clips.stream(project_uuid, voice_uuid, body):
    # handle the returned chunk of data contained in the chunk variable
    # The chunk variable is a byte array of shorts (int 16) representing a chunk of a WAV audio file.
    pass
except:
  # handle exceptions
  pass`
}
function getNodeJsStreamingCode(projectUuid: string, voiceUuid: string, voiceTagContents: string): string {
  return `
  import { Resemble } from '@resemble/node'
     
  // It's very important that you configure the resemble library with the synServerUrl option. Streaming will not
  // work otherwise. You can obtain this URL by contacting resemble.
  Resemble.setApiKey('YOUR_API_TOKEN')
  Resemble.setSynthesisUrl('YOUR_SYNTH_ENDPOINT')
      
  const projectUuid = '${projectUuid}'
  const voiceUuid = '${voiceUuid}'
      
  try {
      for await (const chunk of resemble.clips.stream({
          data: \`${voiceTagContents}\`,
          project_uuid: projectUuid,
          voice_uuid: voiceUuid,
      })) {
          // handle the returned chunk of data contained in the chunk variable
          // The chunk variable is a byte array of shorts (int 16) representing a chunk of a WAV audio file.
      }
  } catch (e) {
      // Handle errors here
 }`
}
