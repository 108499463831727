import * as Tabs from '@radix-ui/react-tabs'
import React, { useEffect, useState } from 'react'
import { RxClipboardCopy } from 'react-icons/rx'
import Select from 'react-select'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { railscasts } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import { sendDesktopEditorAnalytics } from '@/entrypoints/magick'

import { getCodeStringFor } from './CodeViewerUtils'

export type CodeViewerProps = {
  voiceUuid: string
  projectUuid: string
  clipBodyPromise: Promise<string>
  voices: Resemble.VoiceOption[]
  location: string
  showTabs?: ('async' | 'sync' | 'direct sync' | 'streaming')[]
}

export const CodeViewer = (props: CodeViewerProps) => {
  const { showTabs = ['async', 'sync', 'direct sync', 'streaming'] } = props
  const voiceApiSupport = props.voices.find((v) => v.uuid === props.voiceUuid)?.api_support
  const isNonResembleVoice = props.voices.find((v) => v.uuid === props.voiceUuid)?.source !== 'resemble'

  return (
    <Tabs.Root
      onValueChange={(value) => {
        sendDesktopEditorAnalytics(`${props.location}: View Code: Tab Changed`, {
          tab: value,
        })
      }}
      defaultValue={showTabs[0]}
      className='tw-flex tw-flex-col tw-gap-6'
      defaultChecked
    >
      <Tabs.List aria-label='Create Clips using API' className='tw-border-b tw tw-border-solid tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-[#ddd] tw-flex'>
        {showTabs.includes('async') && (
          <Tabs.Trigger
            className='tw-py-3 data-[state=active]:tw-text-accent hover:tw-text-accentdark tw-border-solid tw-px-8 tw-border-2 tw-border-transparent tw-border-l-0 tw-border-r-0 tw-border-t-0 data-[state=active]:tw-border-accent tw-select-none'
            value='async'
          >
            Async
          </Tabs.Trigger>
        )}
        {showTabs.includes('sync') && (
          <Tabs.Trigger
            className='tw-py-3 data-[state=active]:tw-text-accent hover:tw-text-accentdark tw-border-solid tw-px-8 tw-border-2 tw-border-transparent tw-border-l-0 tw-border-r-0 tw-border-t-0 data-[state=active]:tw-border-accent tw-select-none'
            value='sync'
          >
            Sync
          </Tabs.Trigger>
        )}
        {showTabs.includes('direct sync') && (
          <Tabs.Trigger
            className='tw-py-3 data-[state=active]:tw-text-accent hover:tw-text-accentdark tw-border-solid tw-px-8 tw-border-2 tw-border-transparent tw-border-l-0 tw-border-r-0 tw-border-t-0 data-[state=active]:tw-border-accent tw-select-none'
            value='direct sync'
          >
            Sync
          </Tabs.Trigger>
        )}
        {showTabs.includes('streaming') && (
          <Tabs.Trigger
            className='tw-py-3 data-[state=active]:tw-text-accent hover:tw-text-accentdark tw-border-solid tw-px-8 tw-border-2 tw-border-transparent tw-border-l-0 tw-border-r-0 tw-border-t-0 data-[state=active]:tw-border-accent tw-select-none'
            value='streaming'
          >
            Streaming
          </Tabs.Trigger>
        )}
      </Tabs.List>
      <Tabs.Content value='async'>
        {voiceApiSupport && !voiceApiSupport.async ? (
          <p className='tw-px-3 tw-py-2 tw-text-gray-600'>This voice does not support asynchronous synthesis.</p>
        ) : (
          <CodeViewerTab location={props.location} apiType='async' segmentInput={props} />
        )}
      </Tabs.Content>
      <Tabs.Content value='sync'>
        {voiceApiSupport && !voiceApiSupport.sync ? (
          <p className='tw-px-3 tw-py-2 tw-text-gray-600'>This voice does not support synchronous synthesis.</p>
        ) : (
          <CodeViewerTab location={props.location} apiType='sync' segmentInput={props} />
        )}
      </Tabs.Content>
      <Tabs.Content value='direct sync'>
        {voiceApiSupport && !voiceApiSupport.direct_synthesis ? (
          <p className='tw-px-3 tw-py-2 tw-text-gray-600'>This voice does not support sync.</p>
        ) : (
          <CodeViewerTab location={props.location} apiType='direct sync' segmentInput={props} />
        )}
      </Tabs.Content>
      <Tabs.Content value='streaming'>
        {(voiceApiSupport && !voiceApiSupport.streaming) || isNonResembleVoice ? (
          <p className='tw-px-3 tw-py-2 tw-text-gray-600'>This voice does not support streaming.</p>
        ) : (
          <CodeViewerTab location={props.location} apiType='streaming' segmentInput={props} />
        )}
      </Tabs.Content>
    </Tabs.Root>
  )
}
export type ApiType = 'sync' | 'async' | 'streaming' | 'direct sync'
type CodeViewerTabProps = {
  segmentInput: CodeViewerProps
  apiType: ApiType
  location: string
}
const CodeViewerTab = ({ apiType, segmentInput, location }: CodeViewerTabProps) => {
  const options: { label: string; value: 'bash' | 'python' | 'javascript' }[] = [
    {
      value: 'bash',
      label: 'cURL',
    },
    {
      value: 'python',
      label: 'Python',
    },
    {
      value: 'javascript',
      label: 'Node.js',
    },
  ]

  const [selectedLangOption, setSelectedLangOption] = useState(options[0])
  const [codeString, setCodeString] = useState('')

  useEffect(() => {
    setCodeString('')
    async function run() {
      const str = await getCodeStringFor(apiType, selectedLangOption.value, segmentInput)
      setCodeString(str)
    }
    run()
  }, [apiType, segmentInput, selectedLangOption.value])

  if (codeString.length === 0) {
    return <Spinner />
  }

  return (
    <div>
      <div className='tw-flex tw-items-center tw-justify-between tw-p-4 tw-pb-2 tw-bg-slate-700 tw-rounded-2xl tw-rounded-bl-none tw-rounded-br-none'>
        <span className='tw-text-gray-50'>/api/v2</span>
        <div className='tw-flex tw-gap-6'>
          <Select
            options={options}
            isSearchable={false}
            styles={{
              control: (provided) => ({ ...provided, background: 'transparent', border: 'none' }),
              indicatorSeparator: () => ({ display: 'none' }),
              valueContainer: (provided) => ({ ...provided, color: 'white', border: 'none', padding: 0 }),
              singleValue: (provided) => ({ ...provided, color: 'white' }),
              input: (provided) => ({ ...provided, color: 'white' }),
              dropdownIndicator: (provided) => ({ ...provided, paddingLeft: '4px', paddingTop: '0', paddingBottom: '0' }),
            }}
            value={selectedLangOption}
            onChange={(newValue, actionMeta) => {
              if (actionMeta.action == 'select-option' && newValue) {
                setSelectedLangOption(newValue)
                sendDesktopEditorAnalytics(`${location}: View Code: Language Changed`, {
                  apiType: apiType,
                  language: newValue.value,
                })
              }
            }}
          ></Select>
          <button
            className='tw-text-gray-50 focus:tw-ring tw-px-2 tw-rounded tw-flex tw-gap-2 tw-items-center'
            onClick={() => {
              navigator.clipboard.writeText(codeString)
              sendDesktopEditorAnalytics(`${location}: View Code: Code Copied`, {
                apiType,
                language: selectedLangOption.value,
              })
              toast.success('Copied', {
                position: 'top-right',
                autoClose: 2000,
              })
            }}
          >
            <RxClipboardCopy />
            Copy
          </button>
        </div>
      </div>
      <CodeViewerContainer className='tw-px-6 tw-pb-4 tw-bg-gray-800  tw-rounded-2xl tw-rounded-tl-none tw-rounded-tr-none'>
        <SyntaxHighlighter showLineNumbers style={railscasts} startingLineNumber={0} language={selectedLangOption.value}>
          {codeString}
        </SyntaxHighlighter>
      </CodeViewerContainer>
    </div>
  )
}
const CodeViewerContainer = styled.div`
  & pre {
    background: transparent !important;
  }

  & code {
    line-height: 1.8;
    font-size: 0.9rem;
  }

  padding-top: 0;

  & .react-syntax-highlighter-line-number {
    color: white;
  }
`
